import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export const Banner = () => {
  return (
    <div className="w-full h-auto flex items-center justify-center relative">
      {/* @ts-ignore */}
      <StaticQuery<{
        image: Queries.FileConnection;
      }>
        query={graphql`
          query {
            image: allFile(
              filter: {
                relativeDirectory: { eq: "productos" }
                base: { eq: "banner.png" }
              }
            ) {
              edges {
                node {
                  id
                  base
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <div className="w-full h-full pt-20">
            <GatsbyImage
              image={data.image.edges[0].node.childImageSharp?.gatsbyImageData!}
              alt="Banner del producto"
              className="w-full h-full"
              imgStyle={{
                objectFit: "scale-down",
                objectPosition: "top center",
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

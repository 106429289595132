import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/layout/Layout";
import Link from "../components/lib/customLink";
import GrupoAerotecLogos from "../components/themed/grupoAerotecLogos";
import { Banner } from "../components/themed/productosPage/banner";
import { VendedoresButtons } from "../components/themed/VendedoresButtons";
import { removeExtensionFile } from "../helpers/removeExtensionFile";

const ProductosPage = ({
  data,
}: PageProps<{
  productos: Queries.ProductosJsonConnection;
}>) => {
  return (
    <Layout
      navbar={{
        staticColor: "blue",
        staticOpacity: "solid",
      }}
    >
      <Banner />
      <VendedoresButtons />
      <div className="container my-10">
        <div className="text-center mb-4">
          <h1 className="text-aerotec-dark-25 font-bold text-5xl mb-2 md:text-7xl">
            Productos
          </h1>
          <p className="text-gray-800 text-xl md:text-2xl">
            Conoce la amplia variedad de productos que te ofrece Aerotec
            Publicidad.
          </p>
        </div>
        <div className="my-10 flex flex-wrap gap-y-6 justify-center">
          {data.productos.edges.map(({ node: producto }) => (
            <Link
              to={`/productos/${producto.slug}`}
              className="flex-grow flex-shrink basis-full md:basis-1/2 flex justify-center items-center lg:basis-1/3"
              key={producto.id}
            >
              <GatsbyImage
                image={
                  producto.product_page_image.childImageSharp?.gatsbyImageData!
                }
                alt={removeExtensionFile(producto.product_page_image.base)}
                className=""
              />
            </Link>
          ))}
        </div>
        <br />
        <br />
        <GrupoAerotecLogos />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    productos: allProductosJson {
      edges {
        node {
          id
          product_page_image {
            base
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          slug
        }
      }
    }
  }
`;

export default ProductosPage;
